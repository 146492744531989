window.global ||= window;

import "./log";
import React from "react";
import ReactDOM from "react-dom";
import { handleLoadError } from "./reload";
import { createDocumentRoot } from "./root";
// Force a reload when page is loaded from bfcache
window.onpageshow = (event) => {
	if (event.persisted) {
		window.location.reload();
	}
};

const intlPolyfillPromise = () => {
	// Safari < 10, Firefox < 29, Chrome < 24
	// https://caniuse.com/#feat=internationalization
	if (!global.Intl) {
		return import("intl").then((_intl) => {
			return import("intl/locale-data/jsonp/en.js");
		});
	}
};

const scrollPolyfillPromise = () => {
	// Safari (2020-01-27), IE, Edge < 79, Chrome < 61, Firefox < 36
	// https://caniuse.com/#feat=scrollintoview&search=Int
	if (!("scrollBehavior" in document.documentElement.style)) {
		return import("smoothscroll-polyfill").then((smoothscroll) => {
			// enable the polyfill!
			smoothscroll.polyfill();
		});
	}
};

const polyfills = () =>
	Promise.all([intlPolyfillPromise(), scrollPolyfillPromise()]);
const importI18n = () => import("./i18n");
const importStartApp = () =>
	import("./App").then((app) => {
		const root = createDocumentRoot();
		const App = app.default;
		ReactDOM.render(<App />, root);
	});

// Finish one promise before starting the next
const promiseSequence = [polyfills, importI18n, importStartApp];
(async () => {
	for (const fn of promiseSequence) {
		try {
			await fn();
		} catch (e) {
			handleLoadError(e);
			// Rethrow so that we can log the error.
			throw e;
		}
	}
})();
