export const createDocumentRoot = () => {
	// Add dynamic top level styles to the document body
	const ui =
		new URLSearchParams(window.location.search).get("ui") || "fullscreen";
	const styles = {
		inline: `
                html, body, #root {
                    margin: 0;
                    padding: 0;
                    box-sizing: border-box;
                    overflow-y: hidden;
                    background-color: transparent;
                }
            `,
		fullscreen: `
                html {
                    overflow-y: scroll;
                }
                html, body, #root {
                    margin: 0;
                    padding: 0;
                    height: 100%;
                    box-sizing: border-box;
                    background-color: #fff;
                }
            `,
	};
	const styleText = styles[ui as keyof typeof styles] || styles.fullscreen;
	const style = document.createElement("style");
	style.type = "text/css";
	if ((style as any).styleSheet) {
		(style as any).styleSheet.cssText = styleText;
	} else {
		style.appendChild(document.createTextNode(styleText));
	}
	document.body.appendChild(style);
	// Create root element
	const root = document.createElement("div");
	root.setAttribute("id", "root");
	document.body.appendChild(root);
	return root;
};
