import { instanceId } from "./instanceId";
import { getApiUrl } from "./url";
import APP_VERSION from "./version";

// Internal event logging for quality of service metrics
const DINTERO_API_URL = getApiUrl();

const urlParams = new URLSearchParams(window.location.search);
const sid = urlParams.get("sid");

const createEventIdCounter = () => {
	let eventId = 0;
	return () => eventId++;
};
const getEventId = createEventIdCounter();

const createElapsedTimer = () => {
	const initiated = performance.now();
	return () => performance.now() - initiated;
};
const getElapsedTime = createElapsedTimer();

const postLogEvent = async (body: any): Promise<boolean> => {
	// private fetch wrapper appending default values
	try {
		if (!DINTERO_API_URL) {
			return false;
		}
		const payload = {
			application: "dintero-checkout",
			sid,
			instanceId,
			appVersion: APP_VERSION,
			eventId: getEventId(),
			elapsedTime: getElapsedTime(),
			...body,
		};

		await fetch(`${DINTERO_API_URL}/v1/logs/events`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Dintero-Checkout-Instance-Id": instanceId,
				"Client-App-Version": APP_VERSION,
			},
			body: JSON.stringify(payload),
		});
		if (import.meta.env.NODE_ENV === "development") {
			console.log(`🪵 LOG EVENT`, {
				...payload,
			});
		}
		return true;
	} catch {
		// fail silently
		console.error("failed to log event", body);
		return false;
	}
};

export const log = (event: any, data?: any): Promise<boolean> => {
	// log a single event
	return postLogEvent({
		event: event,
		data: data,
	});
};

export const timedLogger = () => {
	// returns a function that can be called to log events with a timer
	const timerInstanceId = Math.random().toString(36).substring(2, 13);
	const getTimerElapsedTime = createElapsedTimer();
	const getTimerEventId = createEventIdCounter();

	// return log function with scoped timer and event counter
	return (event: any, data: any) => {
		return postLogEvent({
			timerInstanceId,
			timerEventId: getTimerEventId(),
			timerElapsedTime: getTimerElapsedTime(),
			event,
			data,
		});
	};
};

// {
//     "message": "Uncaught TypeError: Cannot read properties of null (reading 'parentNode')",
//     "filename": "https://checkout.dintero.com/?sid=T11112863.5cJW4vPGwXpdytChGj6Smz",
//     "lineno": 1,
//     "colno": 82,
//     "stack": "TypeError: Cannot read properties of null (reading 'parentNode')\n    at window.onload (<anonymous>:1:82)\n    at <anonymous>:1:201"
// }
const checkIsPayexSandboxParentNodeError = (data: any) => {
	const isSandboxSession = sid?.startsWith("T");
	const hasKnownMessage =
		data?.message ===
		"Uncaught TypeError: Cannot read properties of null (reading 'parentNode')";
	const hasKnownLineNo = data?.lineno === 1;
	const hasKnownColno = data?.colno === 82;
	const hasKnownStack =
		data?.stack ===
		"TypeError: Cannot read properties of null (reading 'parentNode')\n    at window.onload (<anonymous>:1:82)\n    at <anonymous>:1:201";
	return (
		isSandboxSession &&
		hasKnownMessage &&
		hasKnownLineNo &&
		hasKnownColno &&
		hasKnownStack
	);
};

const checkShouldLogErrorEvent = (data: any) => {
	const ignoreChecks = [checkIsPayexSandboxParentNodeError];
	return ignoreChecks.every((checkFn) => !checkFn(data));
};

// Log unhandled runtime errors
window.addEventListener("error", (event) => {
	const { message, filename, lineno, colno, error } = event;
	const data = { message, filename, lineno, colno, stack: error.stack };
	if (checkShouldLogErrorEvent(data)) {
		log("runtime-error", data);
	}
});

// Log unhandled promise rejections (e.g. fetch errors) as runtime errors
window.addEventListener("unhandledrejection", (event) => {
	try {
		const { reason } = event;
		const data = { message: reason.message, stack: reason.stack };
		if (checkShouldLogErrorEvent(data)) {
			log("runtime-error-unhandledrejection", data);
		}
	} catch {
		// nothing to do here
	}
});

export const collectorBankIdFailedLogger = (() => {
	// An IIFE that will log bankId events if  bankId is not successful
	let events: { event: string; data: any }[] = [];

	// Send events when the browser is closed if success is not called
	window.addEventListener("beforeunload", () => {
		if (events.length > 0) {
			log("bankid-collector-failed", events);
		}
	});

	return {
		log: (event: any, data: any) => {
			events.push({ event, data });
		},
		success: () => {
			events = [];
		},
	};
})();

window.onerror = (message, source, lineno, colno, error) => {
	console.error("An error occurred:", message, source, lineno, colno, error);
	return true; // Prevents the default error handling behavior of the browser
};
