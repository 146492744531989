import APP_VERSION from "./version";

const urlParams = new URLSearchParams(window.location.search);
const sid = urlParams.get("sid");
export const instanceId =
	APP_VERSION +
	"-" +
	Math.random().toString(36).substring(7) +
	"-" +
	new Date().getTime() +
	(sid ? `;${sid}` : "");
