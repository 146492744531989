import { createDocumentRoot } from "./root";

const reloadOnce = (key: string) => {
	if (shouldAttemptReload(key)) {
		window.location.reload();
		return true;
	}
	return false;
};

const shouldAttemptReload = (key: string) => {
	// Check for previous attempts
	const hasPreviousAttempt = sessionStorage.getItem("chunk-reload-attempted");
	if (hasPreviousAttempt && hasPreviousAttempt === key) {
		return false;
	}
	sessionStorage.setItem("chunk-reload-attempted", key);
	// Check that persisting attempt in session storage works to avoid
	// never ending refresh loops.
	const didPersistCurrentAttempt = sessionStorage.getItem(
		"chunk-reload-attempted",
	);
	if (didPersistCurrentAttempt === key) {
		return true;
	}
};

const addReloadButton = () => {
	// Create reload button
	const button = document.createElement("button");
	button.innerText = "Reload Checkout";
	button.style.color = "#ffffff";
	button.style.background = "#0000ff";
	button.style.borderRadius = "3px";
	button.style.padding = "10px 11px";
	button.style.fontFamily =
		"-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif";
	button.style.fontWeight = "700";
	button.style.fontSize = "16px";
	button.style.cursor = "pointer";
	button.style.outline = "none";
	button.style.border = "none";
	button.style.width = "100%";

	button.addEventListener("click", () => {
		window.location.reload();
	});
	document.body.addEventListener("keydown", function (e) {
		if (e.key === "Enter" || e.keyCode === 13) {
			window.location.reload();
		}
	});

	// // Create an error message
	// const icon = document.createElement('h1');
	// icon.innerText = '⚠';
	// icon.style.fontSize = '10rem';
	// icon.style.lineHeight = '9rem';
	// icon.style.fontWeight = '200';
	// icon.style.margin = '0';
	// icon.style.padding = '0';
	// icon.style.fontFamily = '-apple-system, BlinkMacSystemFont, \'Segoe UI\', Roboto, Helvetica, Arial, sans-serif';
	// icon.style.color = '#EB001B';
	// icon.style.textAlign = 'center';

	// Create an error message
	const message = document.createElement("p");
	message.innerText = `Sorry, Dintero Checkout couldn't load. Please verify your internet connection and refresh the page.`;
	message.style.fontSize = "1rem";
	message.style.fontFamily =
		"-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif";
	message.style.color = "#00000";

	// Create a card for the message and button
	const card = document.createElement("form");
	card.style.display = "flex";
	card.style.flexDirection = "column";
	card.style.justifyContent = "center";
	card.style.alignItems = "center";
	card.style.padding = "20px 30px";
	card.style.width = "300px";
	card.style.border = "0";
	card.style.borderRadius = "5px";
	card.style.boxShadow = "0 1 15px rgba(0,0,0,2)";
	card.style.background = "#ffffff";

	// Create a container for the button to center it
	const container = document.createElement("div");
	container.style.display = "flex";
	container.style.justifyContent = "center";
	container.style.alignItems = "center";
	container.style.height = "100vh"; // to center it vertically in the viewport

	// Append to dom
	// card.appendChild(icon);
	card.appendChild(message);
	card.appendChild(button);
	container.appendChild(card);

	const root = document.getElementById("root") || createDocumentRoot();
	root.appendChild(container);
};

const regex = /Loading chunk (\d+) failed/;

export const handleLoadError = (error: any) => {
	try {
		if (regex.test(error?.message || "")) {
			const autoReload = reloadOnce("true");
			// If no reload show reload button
			if (!autoReload) {
				addReloadButton();
				throw new Error("from handleLoadError");
			}
		}
	} catch (e) {
		// If this throws errors we rethrow them in a timeout so we will
		// get a log trace of bot the original load error and the
		// handleLoadError error.
		window.setTimeout(() => {
			throw e;
		}, 10);
	}
};
