export const getApiUrl = () => {
	if (import.meta.env.VITE_API_URL) {
		return import.meta.env.VITE_API_URL;
	}
	return `${window.location.protocol}//${window.location.host}`;
};
type Params = {
	[key: string]: string | undefined;
};
export const addParamsToUrl = (base: string, params: Params) => {
	const urlParams = Object.entries(params).reduce((result, [key, value]) => {
		value !== undefined && result.append(key, value);
		return result;
	}, new URLSearchParams());
	const separator = base.indexOf("?") >= 0 ? "&" : "?";
	return `${base}${separator}${urlParams.toString()}`;
};
